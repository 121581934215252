import React, { FC, useState, useRef, Fragment, useEffect } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useSearchContext } from "context/search";
import CommentListing from "components/CommentListing/CommentListing";
import StartRating from "components/StartRating/StartRating";
import Avatar from "shared/Avatar/Avatar";
import StayDatesRangeInput from "./StayDatesRangeInput";
import { Dialog, Transition } from "@headlessui/react";
import ButtonClose from "shared/ButtonClose/ButtonClose";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import DetailPagetLayout from "../Layout";
import GuestsInput from "./GuestsInput";
import GoogleCalendarDateRange from "../GoogleCalendarDateRange";
import { Helmet } from "react-helmet";
import { useFirebase } from "context/firebase";
import { MdErrorOutline } from "react-icons/md";
import { Menu } from "data/types";
import { FaDrumstickBite, FaIceCream, FaUtensils, FaWineGlassAlt } from "react-icons/fa";
import axios from "axios";
import { BACKEND_URL } from "backendUrl";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import NcPlayIcon from "shared/NcPlayIcon/NcPlayIcon";
import NcPlayIcon2 from "shared/NcPlayIcon2/NcPlayIcon2";
import NcImage from "shared/NcImage/NcImage";
import { faChevronRight, faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import "react-image-gallery/styles/css/image-gallery.css";
import SyncLoader from "react-spinners/SyncLoader";
import MobileFooterSticky from "../(components)/MobileFooterSticky";



const StayDetailPageContainer: FC<{}> = () => {
  const videoRef = useRef<HTMLVideoElement | null>(null);
  const { vendorId, hallId, menuId } = useParams(); // Extract both vendorId, hallId, and menuId
  const [isOpenModalAmenities, setIsOpenModalAmenities] = useState(false);
  const [isOpenModalGallery, setIsOpenModalGallery] = useState(false);
  const { guests, selectedVenue, selectedHall, setSelectedHall, selectedDate, selectedType, selectedMenu, setSelectedMenu, setSelectedVenue } = useSearchContext();
  const thisPathname = useLocation().pathname; // Current path
  const router = useNavigate();
  const calendarRef = useRef<any>(null);
  const { getAuth } = useFirebase();
  const [user, setUser] = useState<any>(null);
  const [showError, setShowError] = useState(false);
  const [loading, setLoading] = useState(true);
  const [expandedMenuId, setExpandedMenuId] = useState<string | null>(null);
  // Initialize selectedMenuId differently based on venue type
  const [selectedMenuId, setSelectedMenuId] = useState<string>(() => {
    // For caterers, set selectedMenuId based on URL param or keep the existing selection
    if (selectedVenue?.type === "Caterers") {
      return menuId || selectedMenu?._id || "";
    }
    // For other menu-based venues, select the first menu by default
    else if (selectedVenue?.menu_based) {
      return selectedVenue?.menus[0]?._id || "";
    }
    // For other venues, prioritize hallId or set to empty
    else {
      return hallId || "";
    }
  });
  const [isPlay, setIsPlay] = useState(false);
  const [currentVideo, setCurrentVideo] = useState(0);
  const type = selectedVenue?.type || "Venues";
  const menuRefs = useRef<{ [key: string]: HTMLDivElement | null }>({});
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isTransitioning, setIsTransitioning] = useState(false);
  const mediaRef = useRef(null);


  useEffect(() => {
    const fetchVenueData = async () => {
      // setLoading(true);
      // setSelectedVenue(null); // Clear stale data
      // setSelectedMenu(null); // Clear the selected menu
      // setSelectedHall(null); // Clear the selected hall
      try {
        setLoading(true); // Start loading
        const response = await axios.get(`${BACKEND_URL}places/${vendorId}`);
        setSelectedVenue(response.data);
        setLoading(false); // End loading
      } catch (error) {
        console.error("Error fetching venue data:", error);
        setLoading(false); // End loading even if there's an error
      }
    };

    fetchVenueData();
  }, [vendorId]);

  useEffect(() => {
    if (selectedVenue && selectedVenue.menu_based && selectedVenue.menus.length > 0) {
      if (!selectedMenuId || !selectedMenu) {
        const firstMenu = selectedVenue.menus[0];
        setSelectedMenu(firstMenu); // Set the first menu as the default selected menu
        setSelectedMenuId(firstMenu._id); // Update the selectedMenuId
      }
    }
  }, [selectedVenue, selectedMenuId, selectedMenu, setSelectedMenu, setSelectedMenuId]);


  useEffect(() => {
    const fetchHallOrMenuData = async () => {
      try {
        if (!selectedVenue) return;
        setLoading(true); // Start loading
        if (selectedVenue.type === "Caterers" && menuId) {
          const { data } = await axios.get(`${BACKEND_URL}menus/${menuId}`);
          setSelectedMenu(data);
        } else if (selectedVenue.type !== "Caterers" && hallId) {
          const { data } = await axios.get(`${BACKEND_URL}halls/${hallId}`);
          setSelectedHall(data);
        }
        setLoading(false); // End loading
      } catch (error) {
        console.error("Error fetching hall/menu data:", error);
        setLoading(false); // End loading even if there's an error
      }
    };

    fetchHallOrMenuData();
  }, [selectedVenue, menuId, hallId,]);



  useEffect(() => {
    if (selectedVenue && selectedMenuId) {
      const menu = selectedVenue.menus.find((menu: Menu) => menu._id === selectedMenuId);
      setSelectedMenu(menu || null);
    }
  }, [selectedMenuId, selectedVenue]);

  useEffect(() => {
    if (selectedVenue?.type === "Caterers") {
      setSelectedHall(null); // Reset hall when switching to a caterer
    } else {
      setSelectedMenu(null); // Reset menu when switching to a non-caterer venue
    }
  }, [selectedVenue]);

  useEffect(() => {
    const captureFrame = (video: HTMLVideoElement, time = 5) => {
      if (!video) return;

      const canvas = document.createElement("canvas");
      const context = canvas.getContext("2d");
      if (!context) return;

      // Set the video to the desired time and add event listeners
      video.currentTime = time;

      // Add an event listener to capture the frame when seeking is complete
      const handleSeeked = () => {
        canvas.width = video.videoWidth;
        canvas.height = video.videoHeight;
        context.drawImage(video, 0, 0, canvas.width, canvas.height);

        // Convert canvas content to an image data URL and set as poster
        const imgData = canvas.toDataURL("image/jpeg");
        video.setAttribute("poster", imgData);
        // Remove the event listener after capturing
        video.removeEventListener('seeked', handleSeeked);
      };

      // Event listener for capturing the video frame once the video has seeked to the desired time
      video.addEventListener('seeked', handleSeeked);

      // Error handling: fallback if the video doesn't seek properly
      video.addEventListener('error', () => {
        console.error('Error loading video frame');
      });
    };

    if (videoRef.current) {
      captureFrame(videoRef.current);
    }
  }, []);


  useEffect(() => {
    const auth = getAuth();
    const unsubscribe = auth.onAuthStateChanged((currentUser) => {
      setUser(currentUser);
    });
    return () => unsubscribe();
  }, [getAuth]);

  function closeModalAmenities() {
    setIsOpenModalAmenities(false);
  }

  function openModalAmenities() {
    setIsOpenModalAmenities(true);
  }

  const handleOpenModalGallery = () => {
    setIsOpenModalGallery(true);
  };

  const handleCloseModalGallery = () => {
    setIsOpenModalGallery(false);
  };

  const handleReserve = () => {
    if (!selectedDate || guests === 0) {
      setShowError(true);
      return;
    }

    setShowError(false);

    if (!user) {
      router("/login", { state: { from: thisPathname } });
      return;
    }

    router("/checkout");
  };

  useEffect(() => {
    if (selectedVenue && selectedMenuId) {
      const menu = selectedVenue.menus.find((menu: Menu) => menu._id === selectedMenuId);
      setSelectedMenu(menu || null); // Update the selectedMenu with the corresponding menu
    }
  }, [selectedMenuId, selectedVenue]);

  if (!selectedVenue || (selectedVenue.type !== "Caterers" && !selectedHall)) {
    <div>No data available for the selected venue.</div>;
  }

  const renderSection1 = () => {
    return (
      <div className="listingSection__wrap !space-y-6">
        <h2 className="text-xl sm:text-3xl lg:text-4xl font-semibold">
          {selectedVenue?.type === "Caterers" && selectedMenu ? selectedMenu.name : selectedHall?.name}
        </h2>

        <div className="flex items-center space-x-4">
          <StartRating reviewCount={selectedVenue?.userRatingCount} point={selectedVenue?.rating} />
          <span>·</span>
          <span>
            <i className="las la-map-marker-alt"></i>
            <span className="ml-1"> {selectedVenue?.address}</span>
          </span>
        </div>
        <div className="flex items-center">
          <Avatar hasChecked sizeClass="h-10 w-10" radius="rounded-full" />
          <span className="ml-2.5 text-neutral-500 dark:text-neutral-400">
            Verified by{" "}
            <span className="text-neutral-900 dark:text-neutral-200 font-medium">
              tayari.
            </span>
          </span>
        </div>
        <div className="w-full border-b border-neutral-100 dark:border-neutral-700" />
        <div className="flex items-center justify-between xl:justify-start space-x-8 xl:space-x-12 text-sm text-neutral-700 dark:text-neutral-300">
          {/* Conditionally render the guest count */}
          {selectedVenue?.type === "Venues" && selectedHall && typeof selectedHall.seating_capacity === 'number' && selectedHall.seating_capacity > 0 ? (
            <div className="flex items-center space-x-3">
              <i className="las la-user text-2xl"></i>
              <span className="">
                {selectedHall.seating_capacity} Guests
              </span>
            </div>
          ) : (
            // Render alternative content if the conditions are not met
            <div className="flex items-center space-x-3">
              <i className="las la-shower text-2xl"></i>
              <span className="">
                <span className="sm:inline-block">Washrooms</span>
              </span>
            </div>
          )}

          {/* Render the remaining amenities */}
          <div className="flex items-center space-x-3">
            <i className=" las la-car text-2xl"></i>
            <span className=" ">
              <span className=" sm:inline-block">Car Parking</span>
            </span>
          </div>
        </div>
      </div>
    );
  };
  const renderSection2 = () => {
    return (
      <div className="listingSection__wrap">
        <h2 className="text-2xl font-semibold">Details</h2>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
        <div className="text-neutral-6000 dark:text-neutral-300">
          {selectedVenue?.type === "Caterers" && selectedMenu ? (
            <span>{selectedMenu.menu_description || "No description available."}</span>
          ) : (
            <span>{selectedHall?.description}</span>
          )}
        </div>
      </div>
    );
  };


  const renderSection3 = () => {
    const defaultAmenities = {
      Venues: [
        { name: "Car Parking", icon: "la-car" },
        { name: "Smoking", icon: "la-smoking" },
        { name: "Wheelchair", icon: "la-wheelchair" },
        { name: "Washroom", icon: "la-restroom" },
        { name: "Wifi", icon: "la-wifi" },
        { name: "Fire Exit", icon: "la-fire-extinguisher" },
      ],
      Caterers: [
        { name: "Vegetarian Options", icon: "las la-seedling" },
        { name: "Non-Vegetarian Options", icon: "las la-drumstick-bite" },
        { name: "Desserts", icon: "las la-ice-cream" },
        { name: "Beverages", icon: "las la-coffee" },
      ],
      Decorators: [
        { name: "Lighting", icon: "las la-lightbulb" },
        { name: "Flower Arrangement", icon: "las la-spa" },
        { name: "Thematic Decor", icon: "las la-paint-brush" },
        { name: "Seating Arrangement", icon: "las la-chair" },
      ],
      Photographers: [
        { name: "Photo Editing", icon: "las la-camera-retro" },
        { name: "Videography", icon: "las la-video" },
        { name: "Drone Shots", icon: "las la-helicopter" },
        { name: "Event Coverage", icon: "las la-film" },
      ],
    };

    const venueType = selectedVenue?.type || "Venues";
    let amenities = [];

    if (selectedHall?.amenities_hall?.length) {
      amenities = selectedHall.amenities_hall;
    } else if (selectedVenue?.amenities?.length) {
      amenities = selectedVenue.amenities;
    } else {
      amenities = defaultAmenities[venueType as keyof typeof defaultAmenities];
    }

    // Separate amenities with and without hyphens
    const singlePartAmenities = amenities.filter(item => !item.name.includes("-"));
    const multiPartAmenities = amenities.filter(item => item.name.includes("-"));

    // Sort multi-part amenities by the combined length of their parts
    multiPartAmenities.sort((a, b) => {
      const aLength = a.name.split("-").reduce((acc, part) => acc + part.trim().length, 0);
      const bLength = b.name.split("-").reduce((acc, part) => acc + part.trim().length, 0);
      return bLength - aLength; // Sort in descending order
    });

    // Combine multi-part amenities (sorted) with single-part amenities (in original order)
    const sortedAmenities = [...multiPartAmenities, ...singlePartAmenities];

    return (
      <div className="listingSection__wrap">
        <div>
          <h2 className="text-2xl font-semibold">Amenities</h2>
          <span className="block mt-2 text-neutral-500 dark:text-neutral-400">
            {`About the ${venueType}'s amenities and services`}
          </span>
        </div>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
        <div className="grid grid-cols-1 xl:grid-cols-3 gap-6 text-sm text-neutral-700 dark:text-neutral-300">
          {sortedAmenities
            .filter((_, i) => i < 12)
            .map((item, i) => (
              <div key={item.name || i} className="flex items-start space-x-2">
                {selectedHall?.amenities_hall?.length ||
                  selectedVenue?.amenities?.length ? (
                  item.icon && (
                    <img
                      src={item.icon}
                      alt={item.name}
                      className="w-8 h-8 filter dark:invert"
                    />
                  )
                ) : (
                  <i className={`text-3xl las ${item.icon} dark:text-neutral-300`}></i>
                )}

                {/* Check if the text has a hyphen */}
                {item.name.includes("-") ? (
                  <ul className="list-disc pl-5">
                    {/* Split the item name by '-' and map each part to a bullet point */}
                    {item.name.split("-").map((line, index) =>
                      line.trim() && <li key={index}>{line.trim()}</li>
                    )}
                  </ul>
                ) : (
                  <span>{item.name}</span>
                )}
              </div>
            ))}
        </div>
      </div>
    );
  };

  const renderSection7 = () => {
    return (
      <div className="listingSection__wrap">
        <div>
          <h2 className="text-2xl font-semibold">Location</h2>
          <span className="block mt-2 text-neutral-500 dark:text-neutral-400">
            {selectedVenue?.address}
          </span>
        </div>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700" />
        <div className="aspect-w-5 aspect-h-5 sm:aspect-h-3 ring-1 ring-black/10 rounded-xl z-0">
          <div className="rounded-xl overflow-hidden z-0">
            <iframe
              title="Venue Location"
              width="100%"
              height="100%"
              loading="lazy"
              allowFullScreen
              referrerPolicy="no-referrer-when-downgrade"
              src={`https://www.google.com/maps/embed/v1/place?key=AIzaSyDK2aEOZsu_lWBxzIJf4b9hs3IcQT4YoP8&q=${selectedVenue?.map.lat},${selectedVenue?.map.lng}`}
            ></iframe>
          </div>
        </div>
      </div>
    );
  };

  const renderSection6 = () => {
    // Check if selectedVenue exists, placeReviews exist, and there are reviews present
    if (!selectedVenue || !selectedVenue.placeReviews || selectedVenue.placeReviews.length === 0) {
      return null;
    }

    return (
      <div className="listingSection__wrap">
        <h2 className="text-2xl font-semibold">
          Reviews ({selectedVenue.placeReviews.length} reviews)
        </h2>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
        <div className="divide-y divide-neutral-100 dark:divide-neutral-800">
          {selectedVenue.placeReviews.map((review, index) => (
            <CommentListing
              key={index}
              className="py-8"
              data={{
                name: review.author_name,
                avatar: review.profile_photo_url,
                date: new Date(review.time).toLocaleDateString(),
                comment: review.text,
                starPoint: review.rating,
              }}
            />
          ))}
        </div>
      </div>
    );
  };


  const renderSection8 = () => {
    return (
      <div className="listingSection__wrap">
        <h2 className="text-2xl font-semibold">Things to know</h2>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700" />
        <div>
          <h4 className="text-lg font-semibold">Cancellation policy</h4>
          <span className="block mt-3 text-neutral-500 dark:text-neutral-400">
            {selectedVenue?.cancellation_policy && (
              <ul className="list-disc list-inside">
                {selectedVenue.cancellation_policy.split(',').map((policy, index) => {
                  // Check if the policy contains a monetary value pattern like "1,500"
                  const isMonetaryValue = /\d{1,3}(,\d{3})*(\.\d+)?/.test(policy.trim());
                  if (isMonetaryValue) {
                    return <span key={index}>{policy.trim()}</span>;
                  }
                  return <li key={index}>{policy.trim()}</li>;
                })}
              </ul>
            )}
            <br />
            To cancel your booking, please contact us at <span className="text-custom-color">+92 323 3329988.</span>
          </span>

        </div>
        {/* <div className="w-14 border-b border-neutral-200 dark:border-neutral-700" /> */}
        {/* <div>
          <h4 className="text-lg font-semibold">Special Note</h4>
          <div className="prose sm:prose">
            <ul className="mt-3 text-neutral-500 dark:text-neutral-400 space-y-2">
              <li>
                Ban and I will work together to keep the landscape and
                environment green and clean by not littering, not using
                stimulants and respecting people around.
              </li>
              <li>Do not sing karaoke past 11:30</li>
            </ul>
          </div>
        </div> */}
      </div>
    );
  };

  const renderSidebar = () => {
    const guestCount = isNaN(guests) ? 0 : guests;

    // Determine if the venue is menu-based
    const isMenuBased = selectedVenue?.menu_based || selectedVenue?.type === "Caterers";
    const venueType = selectedVenue?.type;

    // Determine the price to display, prioritizing the base price if available
    let displayPrice;
    if (isMenuBased && selectedMenu) {
      displayPrice = selectedMenu.price;
    } else if (selectedHall?.hasBasePrice) {
      displayPrice = selectedHall?.basePrice;
    } else {
      displayPrice = selectedHall?.price;
    }

    // Calculate the total price based on the selected hall's pricing structure
    const totalPrice = (
      (isMenuBased || selectedHall?.hall_isperperson)
        ? (displayPrice ?? 0) * guestCount // Use 0 as a fallback for undefined displayPrice
        : (displayPrice ?? 0) // Use 0 as a fallback for undefined displayPrice
    ).toLocaleString(); // Safely calling toLocaleString()

    // Determine the appropriate error message
    let errorMessage = '';
    if (!selectedDate && guestCount === 0) {
      errorMessage = 'Please select Date and Guest Count';
    } else if (!selectedDate) {
      errorMessage = 'Please select a date';
    } else if (guestCount === 0) {
      errorMessage = 'Please choose Guest Count';
    }

    return (
      <div className="listingSectionSidebar__wrap shadow-xl">
        <div className="flex justify-between">
          <span className="text-3xl font-semibold">
            PKR {displayPrice?.toLocaleString()}
            {""}
            {(selectedHall?.hall_isperperson || isMenuBased) && (
              <span className="text-sm font-medium text-neutral-500">
                /per person
              </span>
            )}
          </span>
          <StartRating
            reviewCount={selectedVenue?.userRatingCount}
            point={selectedVenue?.rating}
          />
        </div>
        {showError && errorMessage && (
          <div className="flex items-center text-red-600 font-medium mt-4">
            <MdErrorOutline className="h-5 w-5 mr-2" />
            {errorMessage}
          </div>
        )}
        <form className="flex flex-col border border-neutral-200 dark:border-neutral-700 rounded-3xl">
          <StayDatesRangeInput
            className={`flex-1 z-[11] ${showError && !selectedDate ? 'border-red-500' : ''
              }`}
          />
          <div className="w-full border-b border-neutral-200 dark:border-neutral-700"></div>
          <GuestsInput
            type={selectedVenue?.type || "Venues"}
            className={`${showError && guests === 0 ? 'border-red-500' : ''}`}
          />
        </form>
        <div className="flex flex-col space-y-4">
          <div className="flex justify-between text-neutral-6000 dark:text-neutral-300">
            <span>Vendor Rent</span>
            <span>PKR {totalPrice}</span>
          </div>
          <div className="flex justify-between text-neutral-6000 dark:text-neutral-300">
            <span>Taxes & Fees</span>
            <span>-</span>
          </div>
          <div className="border-b border-neutral-200 dark:border-neutral-700"></div>
          <div className="flex justify-between font-semibold">
            <span>Total Payable</span>
            <span>PKR {totalPrice}</span>
          </div>
        </div>
        <ButtonPrimary onClick={handleReserve}>Book Now</ButtonPrimary>
      </div>
    );
  };

  const handleMenuClick = (menu: Menu) => {
    setSelectedMenuId(menu._id);
  };

  const renderMenuSection = () => {
    // Return null if there are no menus or if the venue is not menu-based
    if (!selectedVenue?.menu_based || selectedVenue.menus.length === 0) return null;

    return (
      <div className={`${selectedVenue?.type === "Caterers" ? "pb-10" : "py-10"} listingSectionSidebar__wrap hidden md:block`}>
        <h2 className="text-2xl font-semibold">Menu Pricing</h2>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700" />

        {/* Responsive layout */}
        <div className="flex flex-col md:flex-row">
          {/* Conditionally render the left side if the venue type is not "Caterers" */}
          {selectedVenue?.type !== "Caterers" && (
            <div className="relative flex-shrink-0 w-full md:w-1/3 h-auto md:h-[490px] rounded overflow-x-auto md:overflow-y-auto pr-4 mb-4 md:mb-0">
              <div className="flex md:block space-x-4 md:space-x-0">
                {selectedVenue.menus.map((menu: Menu) => (
                  <div
                    key={menu._id}
                    className={`p-4 mb-2 cursor-pointer flex items-center justify-between border rounded-md 
                    ${selectedMenuId === menu._id ? 'border-teal-700' : 'border-neutral-200 dark:border-neutral-700'} 
                    ${selectedMenuId === menu._id ? 'bg-teal-700 text-white' : 'bg-gray-50 dark:bg-neutral-800'}
                    hover:shadow-xl dark:hover:shadow-md transition-shadow w-3/4 md:w-full`}
                    onClick={() => setSelectedMenuId(menu._id)}
                  >
                    <div>
                      <h4 className={`text-lg font-semibold ${selectedMenuId === menu._id ? 'text-white dark:text-white' : 'text-gray-700 dark:text-white'}`}>
                        {menu.name}
                      </h4>
                      <p className={`font-medium text-sm ${selectedMenuId === menu._id ? 'text-white dark:text-neutral-300' : 'text-gray-500'}`}>
                        PKR {menu.price.toLocaleString()}
                      </p>
                    </div>
                    <span className={`text-3xl ${selectedMenuId === menu._id ? 'text-white' : 'text-gray-500'}`}>&#8250;</span>
                  </div>
                ))}
              </div>
            </div>
          )}

          {/* Right side: Display details of the selected menu */}
          <div className="flex-grow">
            {selectedVenue.menus.map((menu: Menu) => (
              menu._id === selectedMenu?._id && (
                <div key={menu._id} className="p-6 bg-white dark:bg-neutral-900 border border-neutral-100 dark:border-neutral-800 rounded-lg shadow-md">
                  <h4 className="text-xl font-semibold">{menu.name}</h4>
                  <p className="font-medium mb-4">Price: PKR {menu.price.toLocaleString()}</p>
                  <div className="grid grid-cols-2 gap-4">
                    {menu.starters?.length > 1 && (
                      <div>
                        <div className="flex items-center mb-2">
                          <FaUtensils className="text-blue-500 mr-2" />
                          <h5 className="font-semibold">Starters</h5>
                        </div>
                        <ul className="ml-6 list-disc">
                          {menu.starters.map((item, index) => (
                            <li key={index} className="text-gray-700 dark:text-neutral-300">{item}</li>
                          ))}
                        </ul>
                      </div>
                    )}
                    {menu.mainCourse?.length > 1 && (
                      <div>
                        <div className="flex items-center mb-2">
                          <FaDrumstickBite className="text-red-500 mr-2" />
                          <h5 className="font-semibold">Main Course</h5>
                        </div>
                        <ul className="ml-6 list-disc">
                          {menu.mainCourse.map((item, index) => (
                            <li key={index} className="text-gray-700 dark:text-neutral-300">{item}</li>
                          ))}
                        </ul>
                      </div>
                    )}
                    {menu.drinks?.length > 1 && (
                      <div>
                        <div className="flex items-center mb-2">
                          <FaWineGlassAlt className="text-purple-500 mr-2" />
                          <h5 className="font-semibold">Drinks</h5>
                        </div>
                        <ul className="ml-6 list-disc">
                          {menu.drinks.map((item, index) => (
                            <li key={index} className="text-gray-700 dark:text-neutral-300">{item}</li>
                          ))}
                        </ul>
                      </div>
                    )}
                    {menu.desserts?.length > 1 && (
                      <div>
                        <div className="flex items-center mb-2">
                          <FaIceCream className="text-yellow-500 mr-2" />
                          <h5 className="font-semibold">Desserts</h5>
                        </div>
                        <ul className="ml-6 list-disc">
                          {menu.desserts.map((item, index) => (
                            <li key={index} className="text-gray-700 dark:text-neutral-300">{item}</li>
                          ))}
                        </ul>
                      </div>
                    )}
                  </div>
                </div>
              )
            ))}
          </div>
        </div>
      </div>
    );
  };


  const renderMenuSectionSm = () => {
    if (!selectedVenue?.menu_based || selectedVenue.menus.length === 0) return null;

    return (
      <div className="py-10 listingSection__wrap md:hidden">
        <h2 className="text-2xl font-semibold">Menu Pricing</h2>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700" />

        {/* Conditionally render the swipeable menu list if the venue type is not "Caterers" */}
        {selectedVenue?.type !== "Caterers" && (
          <div className="overflow-x-auto flex space-x-4 pb-4 h-36">
            {selectedVenue.menus.map((menu: Menu) => (
              <div
                key={menu._id}
                className={`w-52 h-28 p-4 cursor-pointer flex-shrink-0 flex items-center justify-between border rounded-md relative
                  ${selectedMenuId === menu._id ? 'border-teal-700' : 'border-neutral-200 dark:border-neutral-700'} 
                  ${selectedMenuId === menu._id ? 'bg-teal-700 text-white' : 'bg-gray-50 dark:bg-neutral-800'}
                  hover:shadow-xl dark:hover:shadow-md transition-shadow`}
                onClick={() => handleMenuClick(menu)}
              >
                <div>
                  <h4 className={`text-md font-semibold ${selectedMenuId === menu._id ? 'text-white dark:text-white' : 'text-gray-700 dark:text-white'}`}>
                    {menu.name}
                  </h4>
                  <p className={`font-medium text-sm ${selectedMenuId === menu._id ? 'text-white dark:text-neutral-300' : 'text-gray-500'}`}>
                    PKR {menu.price.toLocaleString()}
                  </p>
                </div>
                {/* Downward arrow appears only when the box is selected */}
                {selectedMenuId === menu._id && (
                  <span
                    className="text-2xl absolute bottom-0 left-1/2 transform -translate-x-1/2 translate-y-3/4 text-teal-700"
                  >
                    &#9660; {/* Downward arrow */}
                  </span>
                )}
              </div>
            ))}
          </div>
        )}

        {/* Details of the selected menu from ListingStayPage */}
        {selectedVenue.menus.map((menu: Menu) =>
          menu._id === selectedMenu?._id && (
            <div key={menu._id} className="mt-6 p-4 bg-white dark:bg-neutral-900 border border-neutral-100 dark:border-neutral-800 rounded-lg shadow-md">
              <h4 className="text-xl font-semibold">{menu.name}</h4>
              <p className="font-medium mb-4">Price: PKR {menu.price.toLocaleString()}</p>
              <div className="grid grid-cols-1 gap-4">
                {menu.starters && menu.starters.length > 1 && (
                  <div>
                    <div className="flex items-center mb-2">
                      <FaUtensils className="text-blue-500 mr-2" />
                      <h5 className="font-semibold">Starters</h5>
                    </div>
                    <ul className="ml-6 list-disc">
                      {menu.starters.map((item, index) => (
                        <li key={index} className="text-gray-700 dark:text-neutral-300">{item}</li>
                      ))}
                    </ul>
                  </div>
                )}
                {menu.mainCourse && menu.mainCourse.length > 1 && (
                  <div>
                    <div className="flex items-center mb-2">
                      <FaDrumstickBite className="text-red-500 mr-2" />
                      <h5 className="font-semibold">Main Course</h5>
                    </div>
                    <ul className="ml-6 list-disc">
                      {menu.mainCourse.map((item, index) => (
                        <li key={index} className="text-gray-700 dark:text-neutral-300">{item}</li>
                      ))}
                    </ul>
                  </div>
                )}
                {menu.drinks && menu.drinks.length > 1 && (
                  <div>
                    <div className="flex items-center mb-2">
                      <FaWineGlassAlt className="text-purple-500 mr-2" />
                      <h5 className="font-semibold">Drinks</h5>
                    </div>
                    <ul className="ml-6 list-disc">
                      {menu.drinks.map((item, index) => (
                        <li key={index} className="text-gray-700 dark:text-neutral-300">{item}</li>
                      ))}
                    </ul>
                  </div>
                )}
                {menu.desserts && menu.desserts.length > 1 && (
                  <div>
                    <div className="flex items-center mb-2">
                      <FaIceCream className="text-yellow-500 mr-2" />
                      <h5 className="font-semibold">Desserts</h5>
                    </div>
                    <ul className="ml-6 list-disc">
                      {menu.desserts.map((item, index) => (
                        <li key={index} className="text-gray-700 dark:text-neutral-300">{item}</li>
                      ))}
                    </ul>
                  </div>
                )}
              </div>
            </div>
          )
        )}
      </div>
    );
  };

  const isCaterer = selectedVenue?.type === "Caterers";
  const media = isCaterer
    ? selectedMenu?.menu_images ?? []
    : [...(selectedHall?.images ?? []), ...(selectedHall?.videos ?? [])]; // Ensure arrays are defined

  // Unified media rendering function
  const renderGalleryMedia = () => {
    const mediaToDisplay = window.innerWidth < 640 ? media.slice(1, 4) : media.slice(1, 5);

    // Helper function to get a random image from available images
    const getRandomImage = () => {
      const images = [...(selectedHall?.images || []), ...(selectedMenu?.menu_images || [])];
      if (images.length === 0) return "/images/default.jpg"; // Fallback if no images are available
      const randomIndex = Math.floor(Math.random() * images.length);
      return images[randomIndex];
    };

    return mediaToDisplay.map((item, index) => {
      const isVideo = item.endsWith(".mp4");

      return (
        <div
          key={index + 1}
          className="relative rounded-md sm:rounded-xl overflow-hidden aspect-w-1 aspect-h-1 cursor-pointer"
          onClick={() => {
            setCurrentIndex(index + 1);
            setIsOpenModalGallery(true);
            setIsPlay(false);
          }}
        >
          {isVideo ? (
            <>
              <LazyLoadImage
                alt={`Video Thumbnail ${index + 2}`}
                effect="blur"
                src={getRandomImage()} // Use a random image from the available images
                placeholderSrc="/images/placeholder.jpg"
                className="object-cover w-full h-full rounded-md sm:rounded-xl"
              />
              <div className="absolute inset-0 flex items-center justify-center">
                <div onClick={() => setIsPlay(true)} className="cursor-pointer">
                  <NcPlayIcon className="text-white" />
                </div>
              </div>
              <div className="absolute inset-0 bg-neutral-900 bg-opacity-20 opacity-0 hover:opacity-100 transition-opacity"></div>
            </>
          ) : (
            <>
              <LazyLoadImage
                alt={`Image ${index + 2}`}
                effect="blur"
                src={item}
                placeholderSrc="/images/placeholder.jpg"
                className="object-cover w-full h-full rounded-md sm:rounded-xl"
              />
              <div className="absolute inset-0 bg-neutral-900 bg-opacity-20 opacity-0 hover:opacity-100 transition-opacity"></div>
            </>
          )}
        </div>
      );
    });
  };


  // Navigate to the previous media item
  const handlePrev = () => {
    setCurrentIndex((prevIndex) => (prevIndex === 0 ? media.length - 1 : prevIndex - 1));
    setIsPlay(false);
  };

  // Navigate to the next media item
  const handleNext = () => {
    setCurrentIndex((prevIndex) => (prevIndex === media.length - 1 ? 0 : prevIndex + 1));
    setIsPlay(false);
  };

  // Custom modal rendering function
  const renderGalleryModal = () => {
    const currentMedia = media[currentIndex];
    const isVideo = currentMedia?.endsWith(".mp4");

    const getRandomImage = () => {
      const images = [...(selectedHall?.images || []), ...(selectedMenu?.menu_images || [])];
      if (images.length === 0) return "/images/default.jpg"; // Fallback if no images are available
      const randomIndex = Math.floor(Math.random() * images.length);
      return images[randomIndex];
    };

    const handlePlayVideo = () => {
      setIsPlay(true);
      const videoElement = document.getElementById("video-element") as HTMLVideoElement;
      if (videoElement) {
        videoElement.muted = true; // Safari requires video to start muted
        videoElement.play().catch((error) => {
          console.error("Failed to play the video:", error);
        });
      }
    };


    return (
      <Transition appear show={isOpenModalGallery} as={Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 z-50 overflow-y-auto bg-black bg-opacity-95 flex justify-center items-center"
          onClose={() => setIsOpenModalGallery(false)}
        >
          <div className="relative max-w-5xl w-full h-full flex items-center justify-center">
            {/* Previous Button */}
            <button
              onClick={handlePrev}
              className="absolute left-4 top-1/2 z-50 transform -translate-y-1/2 text-white hover:text-gray-300 focus:outline-none"
            >
              <FontAwesomeIcon icon={faChevronLeft} size="2x" />
            </button>

            <div className="relative w-full h-full flex justify-center items-center" ref={mediaRef}>
              {isVideo ? (
                <div className="flex justify-center items-center relative w-full h-full">
                  {!isPlay ? (
                    <>
                      <LazyLoadImage
                        alt="Video Thumbnail"
                        src={getRandomImage()} // Display the thumbnail first
                        className="object-contain max-w-full max-h-[80vh]"
                      />
                      <div className="absolute inset-0 flex items-center justify-center z-10">
                        <div onClick={handlePlayVideo} className="cursor-pointer">
                          <NcPlayIcon className="text-white" />
                        </div>
                      </div>
                    </>
                  ) : (
                    <video
                      id="video-element"
                      controls
                      playsInline
                      muted // Required for autoplay to work on mobile
                      className="object-contain max-w-full max-h-[80vh]"
                    >
                      <source src={currentMedia} type="video/mp4" />
                      Your browser does not support the video tag.
                    </video>
                  )}
                </div>
              ) : (
                <img src={currentMedia} alt="Gallery Image" className="object-contain max-w-full max-h-[80vh]" />
              )}
            </div>

            {/* Next Button */}
            <button
              onClick={handleNext}
              className="absolute right-4 top-1/2 transform z-50 -translate-y-1/2 text-white hover:text-gray-300 focus:outline-none"
            >
              <FontAwesomeIcon icon={faChevronRight} size="2x" />
            </button>

            <ButtonClose
              className="absolute top-4 right-4 z-50 text-white hover:text-black transition-all"
              onClick={() => setIsOpenModalGallery(false)}
            />
          </div>
        </Dialog>
      </Transition>
    );
  };





  return (
    <div className="nc-ListingStayDetailPage">
      <Helmet>
        <title>
          tayari. | {""}
          {loading
            ? "Loading..."
            : selectedVenue?.type === "Caterers"
              ? selectedMenu?.name || "Caterer Menu Details"
              : selectedHall?.name || "Hall Details"}
        </title>
        <meta name="robots" content="noindex" /> {/* Added noindex tag here */}
        <meta
          name="description"
          content="Discover details about your vendor, including images, pricing, availability, and more. Explore the best options for your event on Tayari."
        />

      </Helmet>


      {/* Conditionally show the SyncLoader while loading */}
      {loading ? (
        <div className="flex justify-center items-center min-h-screen">
          <SyncLoader color={"#ffd02b"} loading={loading} size={15} margin={2} />
        </div>
      ) : (
        <>
          <header className="relative rounded-md sm:rounded-xl overflow-hidden">
            <div className="relative grid mt-4 grid-cols-3 sm:grid-cols-4 gap-1 sm:gap-2">
              {/* Main image/video area */}
              <div
                className="col-span-2 row-span-3 sm:row-span-2 relative rounded-md sm:rounded-xl overflow-hidden cursor-pointer aspect-w-1 aspect-h-1"
                onClick={() => {
                  setIsOpenModalGallery(true);
                  setCurrentIndex(0);
                  setIsPlay(false);
                }}
              >
                <LazyLoadImage
                  alt="Main Media Thumbnail"
                  effect="blur"
                  src={
                    selectedVenue?.type === "Caterers"
                      ? selectedMenu?.menu_images?.[0] || "/images/default.jpg"
                      : selectedHall?.images?.[0] ||
                      selectedHall?.videos?.[0]?.replace(".mp4", ".jpg") ||
                      "/images/default.jpg"
                  }
                  placeholderSrc="/images/placeholder.jpg"
                  className="object-cover w-full h-full rounded-md sm:rounded-xl"
                />

                <div className="absolute inset-0 bg-neutral-900 bg-opacity-20 opacity-0 hover:opacity-100 transition-opacity"></div>
              </div>

              {renderGalleryMedia()}
            </div>
          </header>

          <main className="relative z-10 mt-11 flex flex-col lg:flex-row">
            <div className="w-full lg:w-3/5 xl:w-2/3 space-y-8 lg:space-y-10 lg:pr-10">
              {selectedVenue?.type === "Caterers" ? (
                <>
                  {renderSection1()}
                  {renderSection2()}
                  {renderMenuSection()}
                  {renderMenuSectionSm()}
                  {renderSection3()}
                </>
              ) : (
                <>
                  {renderSection1()}
                  {renderSection2()}
                  {renderMenuSection()}
                  {renderMenuSectionSm()}
                  {renderSection3()}
                </>
              )}
              <GoogleCalendarDateRange ref={calendarRef} />
              {renderSection6()}
              {renderSection7()}
              {renderSection8()}
            </div>
            <div className="hidden lg:block flex-grow mt-14 lg:mt-0">
              <div className="sticky top-28">{renderSidebar()}</div>
            </div>
          </main>
          <MobileFooterSticky loading={loading} />

          {renderGalleryModal()}
        </>
      )}

    </div>
  );

};

export default function ListingStayDetailPage() {
  return (
    <DetailPagetLayout>
      <StayDetailPageContainer />
    </DetailPagetLayout>
  );
}
