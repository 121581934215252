import React, { FC } from "react";
import { Helmet } from "react-helmet";

export interface PageContactProps {
  className?: string;
}

const PrivacyPolicy: FC<PageContactProps> = ({ className = "" }) => {
  return (
    <div
      data-nc-id="PageContact"
      className={`bg-white dark:bg-neutral-900 mt-6 min-h-screen flex items-center justify-center text-center lg:p-0 p-4 ${className}`}
    >
      <Helmet>
        <title>tayari. | Privacy Policy</title>
      </Helmet>
      <div className="max-w-4xl mx-auto text-left">
        <h1 className="text-4xl font-bold mb-8">Privacy Policy</h1>
        <p className="mb-4">Last updated: 15-Aug-2024</p>

        <p className="mb-4">
          Welcome to tayari, an online platform for booking wedding venues,
          caterers, decorators, and photographers. Your privacy is important to
          us, and this Privacy Policy explains how we collect, use, and protect
          your personal information when you use our services.
        </p>

        <h2 className="text-2xl font-semibold mb-4">1. Information We Collect</h2>
        <p className="mb-4">
          We collect various types of information in connection with the
          services we provide, including:
        </p>
        <ul className="list-disc list-inside mb-4">
          <li>
            <strong>Personal Information:</strong> When you register or book
            services through our platform, we may collect personal details such
            as your name, email address, phone number, and payment information.
          </li>
          <li>
            <strong>Booking Details:</strong> Information about the wedding
            venue, caterer, decorator, and photographer you book, including
            event dates, guest count, and special requests.
          </li>
          <li>
            <strong>Payment Information:</strong> We collect payment details to
            process transactions through PayFast or to facilitate payments made
            later.
          </li>
          <li>
            <strong>Communication Data:</strong> If you contact us or our
            service providers, we may collect communication data such as
            emails, messages, and phone calls.
          </li>
          <li>
            <strong>Usage Data:</strong> We collect information on how you
            interact with our platform, such as IP address, browser type, device
            type, and pages visited.
          </li>
          <li>
            <strong>Google User Data:</strong> If you interact with our platform
            using Google services (e.g., signing in with Google or integrating
            Google Calendar), we may collect and use certain Google user data as
            authorized by you through the Google OAuth consent screen. This may
            include access to your Google Calendar events and basic profile
            information.
          </li>
        </ul>

        <h2 className="text-2xl font-semibold mb-4">2. How We Use Your Information</h2>
        <p className="mb-4">
          We use the information we collect for various purposes, including:
        </p>
        <ul className="list-disc list-inside mb-4">
          <li>
            <strong>Providing Services:</strong> To process and manage your
            bookings for wedding venues, caterers, decorators, and
            photographers.
          </li>
          <li>
            <strong>Payment Processing:</strong> To facilitate payments through
            PayFast or handle payments to be made later.
          </li>
          <li>
            <strong>Communication:</strong> To contact you regarding your
            bookings, respond to inquiries, and provide customer support.
          </li>
          <li>
            <strong>Improvement of Services:</strong> To analyze usage data to
            improve our platform's performance and enhance user experience.
          </li>
          <li>
            <strong>Marketing:</strong> To send you promotional offers,
            newsletters, and information about our services, if you have opted
            in to receive such communications.
          </li>
          <li>
            <strong>Legal Compliance:</strong> To comply with legal obligations
            and enforce our terms of service.
          </li>
          <li>
            <strong>Google Calendar Integration:</strong> We use Google Calendar
            to add and delete events related to your bookings. This allows you
            to manage your event schedules directly from your Google Calendar.
          </li>
          <li>
            <strong>Google User Data:</strong> We use Google user data solely
            for the purposes disclosed at the time of authorization, including
            the management of events on your Google Calendar, and in compliance
            with Google’s Limited Use requirements. We do not use this data for
            any other purposes, such as advertising or reselling.
          </li>
        </ul>

        <h2 className="text-2xl font-semibold mb-4">3. How We Share Your Information</h2>
        <p className="mb-4">
          We may share your information with third parties in the following
          circumstances:
        </p>
        <ul className="list-disc list-inside mb-4">
          <li>
            <strong>Service Providers:</strong> We may share your information
            with wedding venues, caterers, decorators, photographers, and
            payment processors like PayFast to fulfill your booking.
          </li>
          <li>
            <strong>Legal Requirements:</strong> We may disclose your
            information if required by law or in response to valid legal
            processes such as subpoenas or court orders.
          </li>
          <li>
            <strong>Business Transfers:</strong> In the event of a merger,
            acquisition, or sale of assets, your information may be transferred
            to the acquiring entity.
          </li>
          <li>
            <strong>Third-Party Services:</strong> We may share your data with
            third-party services for analytics, marketing, and customer support
            purposes. These third parties have their own privacy policies and
            are responsible for their practices.
          </li>
          <li>
            <strong>Google User Data:</strong> We do not share Google user data
            with third parties except as necessary to provide the services you
            have requested, such as managing your Google Calendar events, and in
            accordance with Google’s policies.
          </li>
        </ul>

        <h2 className="text-2xl font-semibold mb-4">
          4. Requesting Minimum Relevant Permissions
        </h2>
        <p className="mb-4">
          We are committed to requesting only the minimum necessary permissions
          required to provide our services. We do not request access to
          information that is not critical to our application's functionality.
          Where possible, we request permissions in context (via incremental
          auth) to ensure that users understand why we need specific data.
        </p>

        <h2 className="text-2xl font-semibold mb-4">5. Payment Information</h2>
        <p className="mb-4">
          We take your financial privacy seriously. All payment transactions
          made through PayFast are encrypted and processed securely. If you
          choose to pay later, your payment information will not be stored on
          our servers.
        </p>

        <h2 className="text-2xl font-semibold mb-4">6. Data Security</h2>
        <p className="mb-4">
          We implement reasonable security measures to protect your information
          from unauthorized access, alteration, disclosure, or destruction.
          However, no method of transmission over the internet or electronic
          storage is 100% secure, and we cannot guarantee absolute security.
        </p>

        <h2 className="text-2xl font-semibold mb-4">7. Your Rights</h2>
        <p className="mb-4">
          You have the right to:
        </p>
        <ul className="list-disc list-inside mb-4">
          <li>
            <strong>Access:</strong> Request a copy of the personal information
            we hold about you.
          </li>
          <li>
            <strong>Correction:</strong> Correct any inaccuracies in your
            personal information.
          </li>
          <li>
            <strong>Deletion:</strong> Request the deletion of your personal
            information, subject to legal obligations.
          </li>
          <li>
            <strong>Opt-Out:</strong> Unsubscribe from marketing communications
            by following the opt-out instructions in the emails we send you.
          </li>
          <li>
            <strong>Data Portability:</strong> Request the transfer of your data
            to another service provider.
          </li>
          <li>
            <strong>Restrict Processing:</strong> Limit the processing of your
            data in certain circumstances, such as when the accuracy of the data
            is contested.
          </li>
        </ul>

        <h2 className="text-2xl font-semibold mb-4">8. Data Retention</h2>
        <p className="mb-4">
          We retain your personal information for as long as necessary to
          provide our services, comply with legal obligations, resolve disputes,
          and enforce our agreements. The criteria we use to determine the
          retention period include the nature of the data, the purposes for
          which it was collected, and legal requirements.
        </p>

        <h2 className="text-2xl font-semibold mb-4">9. International Data Transfers</h2>
        <p className="mb-4">
          Your information may be transferred to and processed in countries
          other than your own. We ensure that these data transfers comply with
          applicable data protection laws and that your data remains protected.
        </p>

        <h2 className="text-2xl font-semibold mb-4">10. Third-Party Links</h2>
        <p className="mb-4">
          Our platform may contain links to third-party websites. We are not
          responsible for the privacy practices of those websites and encourage
          you to read their privacy policies.
        </p>

        <h2 className="text-2xl font-semibold mb-4">11. Changes to This Privacy Policy</h2>
        <p className="mb-4">
          We may update this Privacy Policy from time to time. We will notify
          you of any changes by posting the new policy on our platform with an
          updated effective date. If the changes significantly affect how we use
          your personal information, we will notify you directly through email
          or in-app notifications.
        </p>

        <h2 className="text-2xl font-semibold mb-4">12. Compliance with Google’s Privacy Requirements</h2>
        <p className="mb-4">
          This Privacy Policy is hosted within the domain that hosts our
          homepage and is accessible via a link from our homepage. It is also
          linked from the OAuth consent screen on the Google API Console. The
          privacy practices outlined in this policy, including those related to
          Google user data, are in compliance with Google’s Limited Use
          requirements and will be kept up to date. We ensure that users are
          informed about how their Google user data is accessed, used, stored,
          and shared, including the integration with Google Calendar for adding
          and deleting events.
        </p>

        <h2 className="text-2xl font-semibold mb-4">
          13. In-Product Privacy Notifications
        </h2>
        <p className="mb-4">
          We provide in-product privacy notifications prominently within our app
          interface, clearly informing users about how their Google user data is
          used. These notifications are kept up to date and are displayed in a
          way that is easily accessible to users.
        </p>

        <h2 className="text-2xl font-semibold mb-4">14. Enforcement and Compliance</h2>
        <p className="mb-4">
          We access Google API Services in accordance with Google’s API Services
          User Data Policy and Terms of Service. If we change the way we use
          Google user data, we will notify users and obtain their consent before
          making use of the data in a new way. We also ensure that our employees,
          agents, contractors, and successors comply with Google’s API Services
          User Data Policy. Unauthorized or deceptive use of Google user data is
          strictly prohibited.
        </p>

        <h2 className="text-2xl font-semibold mb-4">15. Contact Us</h2>
        <p className="mb-4">
          If you have any questions or concerns about this Privacy Policy or how
          we handle your personal information, please contact us at
          +923043329988.
        </p>

        <p className="mb-4 font-semibold">
          By using our platform, you agree to the terms outlined in this Privacy
          Policy.
        </p>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
